import React from "react"
import { graphql } from "gatsby"

import Navigation from "../components/navigation"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Intro } from "../components/intro"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="Home" />
      <Navigation />
      <Intro />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
  }
`
