import Tag from "../components/tag"
import React, { Component } from "react"

export class Intro extends Component {
  constructor() {
    super()
    this.state = {
      localizedGreetings: { welcome: "Hello", goodbye: "Enjoy your stay!" },
    }
  }

  render() {
    const { localizedGreetings } = this.state
    return (
      <>
        <h1>{localizedGreetings.welcome}*</h1>
        <p>
          My name is Marko Bianchi and my handle is{" "}
          <Tag
            tag="@mbianchihr"
            link="https://twitter.com/mbianchihr"
            external="true"
          />
        </p>
        <p>
          Welcome to my little piece of the internet, here you can find my
          <Tag tag={"Articles"} link={"articles"} />, <br />
          <Tag tag={"Thinking Out Loud"} link={""} />,
          <Tag tag={"~/.config"} link={""} />,{" "}
          <Tag tag={"Change.log"} link={"change-log"} /> and{" "}
          <Tag tag={"Reading list"} link={""} />. I am interested in privacy,
          technology, philosophy, photography and psychology.
        </p>
        <p>
          I am currently working as a software developer with focus on
          Javascript, Golang and overall backend development. You can find out
          more on my <Tag tag={"About"} link="about" /> page.
        </p>
        <p>
          If you are interested in my photography you can find my latest photos
          on <br />
          <Tag
            tag="Photostream"
            link="https://photos.bianchi.hr"
            external="true"
          />
          . I primarily do street photography but I am looking to expand into
          portraits and landscapes.
        </p>
        <p>
          If you are looking to contact me feel free to send an email to
          <br />
          <Tag
            tag="marko@bianchi.hr"
            link="mailto:marko@bianchi.hr"
            external="true"
          />{" "}
          or contact me on
          <Tag
            tag="LinkedIN"
            link="https://www.linkedin.com/in/mbianchihr/"
            external="true"
          />
          .
        </p>
        <p>{localizedGreetings.goodbye}*</p>
        <p style={{ fontSize: "10px" }}>
          *Using your browsers locale to show you personalized greetings and
          goodbye.
        </p>
      </>
    )
  }

  componentDidMount() {
    const localizedGreetings = this.localizeGreet(navigator.language.slice(3))
    this.setState({ localizedGreetings })
  }

  localizeGreet(language) {
    switch (language) {
      case "FR":
        return {
          welcome: "Salut!",
          goodbye: "Profitez de votre séjour!",
        }
      case "DE":
        return {
          welcome: "Hallo!",
          goodbye: "Genieße deinen Aufenthalt!",
        }
      case "ES":
        return {
          welcome: "¡Hola!",
          goodbye: "¡Disfruta tu estancia!",
        }
      case "HR":
        return {
          welcome: "Pozdrav!",
          goodbye: "Uzivajte na stranici!",
        }
      default:
        return {
          welcome: "Hello!",
          goodbye: "Enjoy your stay!",
        }
    }
  }
}
